<template>
  <div class="progress progress-bar-vertical">
    <div
      class="progress-bar"
      role="progressbar"
      aria-valuenow="30"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="`height: ${avance}%; background-color: #${color};`"
    >
      <span class="sr-only">{{ avance }}% completado</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgresoVertical',
  props: {
    avance: Number,
    color: String,
  },
};
</script>

<style lang="scss" scoped>
.progress-bar-vertical {
  width: 90px;
  min-height: 160px;
  display: flex;
  gap: 30px;
  align-items: flex-end;
  float: left;
  border-radius: 0;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
</style>
