<template>
  <div class="estadistica-entidad simulacro">
 
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-sm-7 col-lg-6 col-xl-4">
        <div class="estadisticaEntidad mb-3">
          <h5 class="font-weight-bold mb-3">
            <u>Actas</u>
          </h5>
          <div class="grilla-estadisticas mb-4">
            <div class="esperadas">
              <ProgresoVertical
                class="mb-3"
                :avance="obtenerAvance(estadisticas.actas_esperadas_porcentaje)"
                :color="colorEsperadas"
              />
            </div>
            <div class="capturadas">
              <ProgresoVertical
                class="mb-3"
                :avance="obtenerAvance(estadisticas.actas_capturadas_porcentaje)"
                :color="colorCapturadas"
              />
            </div>
            <div class="contabilizadas">
              <ProgresoVertical
                class="mb-3"
                :avance="obtenerAvance(estadisticas.actas_contabilizadas_porcentaje)"
                :color="colorContabilizadas"
              />
            </div>
            <div class="esperadas">
              <p>
                <span>{{ estadisticas.actas_esperadas_numero }}</span> <br />
                <span>{{ estadisticas.actas_esperadas_porcentaje }}% </span><br />
                Esperadas
              </p>
            </div>
            <div class="capturadas">
              <p>
                <span>{{ estadisticas.actas_capturadas_numero }}</span> <br />
                <span>{{ estadisticas.actas_capturadas_porcentaje }}% </span><br />
                Capturadas
              </p>
            </div>
            <div class="contabilizadas">
              <p>
                <span>{{ estadisticas.actas_contabilizadas_numero }} </span><br />
                <span>{{ estadisticas.actas_contabilizadas_porcentaje }}% </span><br />
                Contabilizadas
              </p>
            </div>
          </div>

          

          
        </div>
      </div>


      <div class="col-12  col-lg-6 col-xl-4 ">
        <div class="estadisticaEntidad mb-3  ">
         <div>
           <h5 class="font-weight-bold mb-3" >Actas Contabilizadas por ubicación de casilla:</h5>
           
         </div>
          <div class="grilla-estadistica-urbana-nourbana col-sm-5 mt-5 pt-4 ">
            <div class="urbana">
              <urbano :color="colorUrbano" />
            </div>
            <div class="no-urbana">
              <no-urbano :color="colorNoUrbano" />
            </div>
            <div :style="styleUrbano" class="pl-3">
              <h5 :style="styleLetter">
                {{ estadisticas.actas_casilla_urbana_numero }} <br />
                <small>Urbanas</small>
              </h5>
            </div>
            <div :style="styleNoUrbano" class="pl-3">
              <h5 :style="styleLetter">
                {{ estadisticas.actas_casilla_nourbana_numero }} <br />
                <small>No Urbanas</small>
              </h5>
            </div>
          </div>
        </div>
      </div>
        
      </div>
      
      
    </div>
  
</template>

<script>
import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical.vue';
import Urbano from '@/components/Movil/componentes/Iconos/Urbano';
import GraficaListaNominal from '@/components/Movil/componentes/GraficaListaNominal';
import NoUrbano from '@/components/Movil/componentes/Iconos/NoUrbano';

export default {
  name: 'EstadisticaEntidad',
  components: {
    ProgresoVertical,
    GraficaListaNominal,
    Urbano,
    NoUrbano,
  },
  data() {
    return {
      randomKey: Math.random(),
    };
  },
  computed: {
    estadisticas() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelEntidad;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelEntidad;
      }
      // return this.$store.state.gEstadisticasNivelEntidad;
    },
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'fd2a71';
      }
      return 'C791A8';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'fe9db0';
      }
      return 'B283B9';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return 'fec5cd';
      }
      return '582D73';
    },
    colorUrbano() {
      if (this.$store.state.esOscuro) {
        return 'F1B1D7';
      }
      return 'd2127e';
    },
    colorNoUrbano() {
      if (this.$store.state.esOscuro) {
        return 'E97FBD';
      }
      return '79144c';
    },
    styleLetter() {
      if (this.$store.state.esOscuro) {
        return 'color:black';
      }
      return 'color:white';
    },
    styleUrbano() {
      if (this.$store.state.esOscuro) {
        return 'background-color: #F1B1D7 !important; color: black !important;';
      }
      return 'background-color: #C791A8 !important;';
    },
    styleNoUrbano() {
      if (this.$store.state.esOscuro) {
        return 'background-color: #E97FBD !important; color: black !important;';
      }
      return 'background-color: #582D73 !important;'; 
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
  },
  methods: {
    obtenerAvance(avance) {
      // console.log(avance);
      return Math.ceil(parseFloat(avance));
    },
  },
  watch: {
    eleccion: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.randomKey = Math.random();
        }
      },
    },
  },
};
</script>
