<template>
  <div >
    <vue-c3 id="graficaListaNominal" :handler="handler"></vue-c3>
  </div>
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';

export default {
  name: 'GraficaListaNominal',
  components: {
    VueC3,
  },
  props: {
    color: String,
    // valores: Object,
    numero: String,
    porcentaje: String,
    value: Boolean,
  },
  data() {
    return {
      handler: new Vue(),
    };
  },
  methods: {
    cambiarEstilo() {
      if (this.$store.state.esOscuro) {
        const element1 = document.getElementsByClassName('c3-gauge-value')[0];
        const element2 = document.getElementsByClassName('c3-chart-arcs-background')[0];
        element1.style.fill = 'white';
        element2.style.fill = '#2C2C2C';
      } else {
        const element1 = document.getElementsByClassName('c3-gauge-value')[0];
        const element2 = document.getElementsByClassName('c3-chart-arcs-background')[0];
        const element3 = document.getElementsByClassName('.c3-chart-arc')[0];
        element1.style.fill = '#707070'; // NUMERO DECASILLAS EN LISTA NOMINAL;
        element1.style.fontWeight = 'bold'; //ESTILO PARA EL NUMERO DE CASILLAS
        // element1.style.fill = '#C791A8';
        element2.style.fill = '#E3E3E3';
        
        if (element3) {
          // element3.style.stroke = '#E3E3E3';
          // element3.style.strokeWidth = '2px';
          element3.style.stroke = '#E3E3E3';
          //element3.style.strokeWidth = '5px'; // Grosor de la línea del arco
        }
      }
    },
  },
  watch: {
    value() {
      this.cambiarEstilo();
    },
  },
  mounted() {
    const options = {
      data: {
        columns: [
          ['data', this.porcentaje],
        ],
        type: 'gauge',
      },
      color: {
        pattern: [`#${this.color}`],
      },
      gauge: {
        label: {
          format: () => this.numero,
          show: false,
        },
        width: 25,
        
      },
      // donut: {
      //   width: 10
      // },
      // padding:{
      //   top: 0,
      //   right: 20,
      //   bottom: 0,
      //   left: 0,
      // },
      size: {
        height: 200,      
        
      },
      subchart: {
        whidth: 10,
      },
      // tooltip: {
      //   show: false,
      //   point: false,
      // },
      interaction: {
        enabled: false,
      },
    };

    this.handler.$emit('init', options);
    this.cambiarEstilo();
  },
};

</script>
